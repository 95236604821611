import(/* webpackMode: "eager", webpackExports: ["ErrorBoundary"] */ "/opt/render/project/src/apps/dash-next/node_modules/next/dist/client/components/error-boundary.js");
;
import(/* webpackMode: "eager" */ "/opt/render/project/src/apps/dash-next/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"],\"weight\":[\"400\",\"600\",\"800\"],\"display\":\"swap\",\"variable\":\"--font-inter\"}],\"variableName\":\"inter\"}");
;
import(/* webpackMode: "eager" */ "/opt/render/project/src/apps/dash-next/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Epilogue\",\"arguments\":[{\"subsets\":[\"latin\"],\"weight\":[\"400\",\"600\",\"800\"],\"display\":\"swap\",\"variable\":\"--font-epilogue\"}],\"variableName\":\"epilogue\"}");
;
import(/* webpackMode: "eager", webpackExports: ["Toaster"] */ "/opt/render/project/src/apps/dash-next/node_modules/react-hot-toast/dist/index.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/render/project/src/apps/dash-next/src/components/Error/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["AuthProvider"] */ "/opt/render/project/src/apps/dash-next/src/providers/auth/AuthProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SessionManager"] */ "/opt/render/project/src/apps/dash-next/src/providers/auth/SessionManager.tsx");
;
import(/* webpackMode: "eager" */ "/opt/render/project/src/apps/dash-next/src/providers/ClientProviders.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/render/project/src/apps/dash-next/src/providers/core/ApolloClientProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["AriaRouterProvider"] */ "/opt/render/project/src/apps/dash-next/src/providers/core/AriaRouterProvider.tsx");
;
import(/* webpackMode: "eager" */ "/opt/render/project/src/apps/dash-next/src/providers/core/ColorModeProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["TranslationsProvider"] */ "/opt/render/project/src/apps/dash-next/src/providers/core/TranslationsProvider.tsx");
;
import(/* webpackMode: "eager" */ "/opt/render/project/src/apps/dash-next/src/providers/event-bus.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/render/project/src/apps/dash-next/src/providers/GrowthBookProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["GlobalLoading"] */ "/opt/render/project/src/apps/dash-next/src/providers/loading/GlobalLoading.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["LoadingProvider"] */ "/opt/render/project/src/apps/dash-next/src/providers/loading/LoadingProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["NavigationProvider"] */ "/opt/render/project/src/apps/dash-next/src/providers/navigation/NavigationProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["InvitationsProvider"] */ "/opt/render/project/src/apps/dash-next/src/providers/organizations/InvitationProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["OrganizationProvider"] */ "/opt/render/project/src/apps/dash-next/src/providers/organizations/OrganizationProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/render/project/src/apps/dash-next/src/providers/PostHogProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/render/project/src/apps/dash-next/src/providers/TrackingProvider/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/render/project/src/apps/dash-next/src/providers/UserProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["WorkspaceProvider"] */ "/opt/render/project/src/apps/dash-next/src/providers/workspaces/WorkspaceProvider.tsx");
;
import(/* webpackMode: "eager" */ "/opt/render/project/src/apps/dash-next/src/styles/globals.css");
;
import(/* webpackMode: "eager" */ "/opt/render/project/src/apps/dash-next/src/styles/legacy-scaffold.css");
