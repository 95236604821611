"use client";

import { useEffect } from "react";
import * as Sentry from "@sentry/react";
import LogRocket from "logrocket";
import { datadogRum } from "@datadog/browser-rum";
export default function Error({
  error,
  reset
}: {
  error: Error & {
    digest?: string;
  };
  reset: () => void;
}) {
  useEffect(() => {
    // Capture error in Sentry
    Sentry.captureException(error);

    // Capture error in Datadog RUM
    datadogRum.addError(error.message, {
      error
    });

    // Capture error in LogRocket
    LogRocket.captureException(error);
  }, [error]);
  return <div data-sentry-component="Error" data-sentry-source-file="index.tsx">
      <h2>Something went wrong!</h2>
      <button onClick={reset}>Try again</button>
    </div>;
}